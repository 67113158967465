.first_courses_section {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.63)), url('../../../../public/pexels-photo-3184357.svg');
  background-size: cover;
  background-position: -10%;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
    font-weight: bold;
  }
  @media screen and (max-width: 767px) {
    background-position: 40%;

  }
}