.footer_down {
  background-image: url("../../../../public/bgi.jpg");
  background-size: cover;

  .footer_down_content {
    padding: 50px 20%;

    .form {
      display: flex;
      margin-top: 50px;

      .input_cont {
        height: 40px;
        width: 80%;
        border: 1px solid white;
        border-radius: 13px;
        padding: 3px 10px;

        input {
          background-color: transparent !important;
          color: white;
          border: none;
          border-left: 1px solid white;
          border-radius: 0;
          padding: 0 4px;
        }

        input::placeholder {
          color: white;
        }

        input:focus, input:focus-visible {
          background-color: transparent;
          border: none;
          outline: none;
          box-shadow: none;
        }
      }

      .submit {
        margin-left: 20px;
        background-color: var(--secondary);
        border-radius: 45px;
        border: none;
        padding: 9px 25px;
        width: auto;
        height: 40px;
        text-transform: uppercase;
        font-size: .8em;
        letter-spacing: 1.5px;
      }
    }

  }

  .menu {
    padding: 10px 20% 50px;
    display: flex;
    justify-content: space-between;
    color: white;

    img {
      height: 25px;
    }

    .link, a {
      text-decoration: none;
      color: white;
      margin-left: 38px;
      font-family: 'Work Sans', sans-serif;
      font-size: 17px;
      line-height: 112%;
      letter-spacing: 0.005em;

      &:hover {
        color: var(--secondary);
      }
    }
  }

  .footer_info {
    background-color: var(--primary);
    padding: 5px 20%;
    display: flex;
    justify-content: space-between;
    p ,a {
      color: #c7c7c7;
      margin: 0;

    }
    a {
      color: white;
      text-decoration: none;
    }
  }
  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    .footer_down_content {
    padding: 60px 10%;
    }
    .menu {
    padding: 60px 10%;

    }
    .footer_info {
      padding: 5px 5%;
      font-size: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .footer_down_content {
      padding: 50px 5%;

      .form {
        display: block;
        .input_cont {
          border: none;
          width: 100%;
          height:auto;
          input {
            display: block !important;
          }
        }
        .submit {
          margin: 0;
          display: block !important;
        }
      }
    }
    .menu {
      display: none;

    }
    .footer_info {
      padding: 5px 5%;
      font-size: 10px;
    }
  }
}