.footer_up {
  padding: 100px 20% 50px;

  p, .icon {
    color: var(--primary);
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
  }

  .map {
    width: 100%;
    height: 100%;
  }

  .brand {
    margin-top: 35px;

    .brand_name {
      font-weight: bold;
      margin: 0;
    }
  }

  .column2 {
    padding-left: 40px;
  }

  .icon {
    margin: 15px;
    margin-top: 0px;
  }

  td {
    vertical-align: top;
    max-width: 250px;
  }

  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;
    .map {
      min-height: 350px;
    }
    .column2 {
      padding-left: 0px;
      padding-top: 50px;
    }

  }
  @media screen and (max-width: 767px) {
    padding: 50px 5%;
    .map {
      min-height: 350px;
    }
    .column2 {
      padding-left: 0px;
      padding-top: 50px;
    }
  }
}