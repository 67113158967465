.fourth_section {
  padding: 100px 20%;
  background-image: url("../../../../public/bgi.jpg");
  background-size: cover;
  display: block;

  .text {
    margin-top: 40px;
    text-align: center;

    h4 {
      color: white;
    }

    p {
      font-family: 'Work Sans', sans-serif;
      font-size: 18px;
      line-height: 20px;
      color: white;
    }

    ul {
      padding: 0;

      li {
        color: white;
        list-style-type: none;
      }
    }
  }

  //infinity animate
  .infinity-path {
    --size: 280;
    --speed: 3.65;
    height: calc(var(--size) * 1px);
    position: relative;
    width: calc(var(--size) * 1px);
    margin: 50px auto;
    //margin-top: 50px;
  }

  .infinity-path > div {
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    border-radius: 100%;
    border: calc(var(--size) / 4 * 1px) solid rgba(255, 255, 255, 0.13);
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation-duration: calc(var(--speed) * 1s);
    animation-duration: calc(var(--speed) * 1s);
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: infinity-spin;
    animation-name: infinity-spin;
    transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0);
  }

  .infinity-path > div:before {
    content: "";
    height: calc(var(--size) / 4 * 1px);
    width: calc(var(--size) / 4 * 1px);
    border-radius: 100%;
    background: var(--secondary);
    position: absolute;
    top: 50%;
    animation: infinity-vanish calc(var(--speed) * 2s) infinite reverse steps(1);
    transform: translate(calc(var(--translate-2) * 2px), calc(var(--translate) * 1%));
  }

  .infinity-path > div:nth-of-type(1) {
    --translate: -50;
    --translate-2: calc(var(--size) / 8);
  }

  .infinity-path > div:nth-of-type(1):before {
    right: 0;
  }

  .infinity-path > div:nth-of-type(2) {
    --translate: 50;
    --translate-2: calc(var(--size) / 8 * -1);
    -webkit-animation-delay: calc(var(--speed) * 1s);
    animation-delay: calc(var(--speed) * 1s);
    animation-direction: reverse;
  }

  .infinity-path > div:nth-of-type(2):before {
    left: 0;
    transform: translate(calc(var(--size) / 4 * -1px), -50%);
    -webkit-animation-direction: normal;
    animation-direction: normal;
  }

  @-webkit-keyframes infinity-vanish {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes infinity-vanish {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  @-webkit-keyframes infinity-spin {
    from {
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
    }
    to {
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
    }
  }
  @keyframes infinity-spin {
    from {
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(0deg);
    }
    to {
      transform: translate(calc(var(--translate) * 1%), 0) translate(calc(var(--translate-2) * 1px), 0) rotate(360deg);
    }
  }

  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;

  }
  @media screen and (max-width: 767px) {
    padding: 50px 5%;
    .text {
      h4, ul {
        padding: 10px;

        li {

        }
      }

    }
    .infinity-path {
      --size: 150;
      margin: 30px auto;
    }
  }
}