.hamburger {
  .bgc {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;

    .burger_links {
      margin-top: 100px;

      .icon {
        position: fixed;
        top: 30px;
        right: 30px;
        width: 30px;
        height: 30px;
      }

      .link {
        text-align: center;
        margin: 0;
        color: white !important;
        font-size: 22px;
      }

      .consult {
        font-size: 22px;
        padding: 10px 50px;
        height: 50px;
        background-color: var(--primary);
        border: none;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin: 20px 0;
    }
  }
}