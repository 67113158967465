.header_wrapper {
  z-index: 100;

  .contacts {
    padding: 5px 30px;
    display: flex;
    font-size: 12px;
    line-height: 112%;
    letter-spacing: 0.005em;
    color: #FFFFFF;
    background-color: var(--primary);
    a, p {
      text-decoration: none;
      color: white;
      margin: 0;
      &:hover {
        color: white;
      }
    }
    .contacts1 {
      display: flex;
      align-items: center;
    .address, .element {
      margin-left: 30px;
    }
      &:first-child {
        margin: 0;
      }
    }
    .contacts2 {
      .icon {
        margin-left: 10px;
        color: #d5d5d5;
      }
    }
  }

  .menu {
    padding: 15px 20%;
    display: flex;
    justify-content: space-between;
    color: white;

    img {
      height: 25px;
    }


    .link {
      text-decoration: none;
      margin-left: 38px;
      font-size: 17px;
      line-height: 112%;
      letter-spacing: 0.005em;

    }

    .consult {
      font-size: 17px;
      background-color: transparent;
      border-radius: 34px;
      padding: 0 35px;
      line-height: 112%;
      letter-spacing: 0.005em;
    }
  }

  .burger_menu {
    display: none;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 0.6s ease;
  background-color: transparent;
.menu {
  padding: 25px 20% 15px;
  background-color: rgba(0, 0, 4, 0.18);
}
  a {
    color: white;

    &:hover {
      color: #FF9643FF;
    }
  }

  .contacts {
    display: flex;
    color: white;
    //margin-bottom: 20px;
    // animate shake==========//
    .shake {
      animation: shake-animation 3s ease infinite;
      transform-origin: 50% 50%;
    }

    .element {
      margin: 0 auto;
      //color: #c7c7c7;
    }

    @keyframes shake-animation {
      0% {
        transform: translate(0, 0)
      }
      1.78571% {
        transform: translate(5px, 0)
      }
      3.57143% {
        transform: translate(0, 0)
      }
      5.35714% {
        transform: translate(5px, 0)
      }
      7.14286% {
        transform: translate(0, 0)
      }
      8.92857% {
        transform: translate(5px, 0)
      }
      10.71429% {
        transform: translate(0, 0)
      }
      100% {
        transform: translate(0, 0)
      }
    }
  }

  .consult {
    border: 1px solid #ffffff;
    color: #ffffff;

    &:hover {
      border: 1px solid var(--primary);
      color: var(--primary);
      font-weight: bold;

    }
  }
}

.header2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.27);
  transform: translateY(10);
  transition: transform 6s ease;
  animation: myanimation 3s;

  .link {
    color: var(--primary);

    &:hover {
      color: var(--secondary);
    }
  }

  .contacts {
    display: none;
  }

  .openIcon {
    color: var(--primary);
  }

  .consult {
    border: 1px solid var(--primary);
    background-color: var(--primary) !important;
    color: white;

    &:hover {
      border: 1px solid var(--secondary);

    }
  }
}

@keyframes myanimation {
  0% {
    background-color: transparent;
    color: white;
  }
  35% {
    background-color: rgba(255, 255, 255, 0.97);
    color: black;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.97);
    color: black;
  }
}

.hamburger {
  display: none;
}

//MEDIA
@media screen and (max-width: 886px) {
  .header_wrapper {
    .hamburger {
      display: block;
      font-size: 25px;
    }

    .contacts {
      padding: 5px 15px;
      font-size: 10px;
      .address {
        display: none;
      }
      .contacts1 {
        .element {
        margin: 0;
        }
        .shake {
          margin-left: 20px;
        }
      }
    }

    .menu {
      padding: 10px 5%;
      display: flex;
      align-items: center;
      img {
        height: 20px;
      }

      .menu_links {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px){
  .header_wrapper {
    .menu {
      padding: 12px 10%;
    }
  }
}