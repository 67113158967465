.consult_modal {
  .input_cont {
    input {
      border: 1px dashed gray;
      border-radius: 7px;
      padding-left: 10px;
      width: 100%;
      height: 40px;
      color: black;
      &:focus, &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .submit {
    width: 100%;
    height: 40px;
    border-radius: 7px;
    background-color: var(--secondary);
    border-color: var(--secondary);
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-decoration: none;
    font-size: .9em;
    letter-spacing: 1.5px;
  }
  .ant-modal-body {
    padding-bottom: 20px;
  }
}
