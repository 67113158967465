.second_courses_section {
  padding: 70px 20%;
  .text {
    color: var(--primary);
    font-size: 18px;
    line-height: 120%;
  }
  img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;

  }
  @media screen and (max-width: 767px) {
    padding: 50px 5%;

  }
}