.second_section {
  padding: 100px 20%;

  .text {
    width: 50%;

    p {
      color: var(--primary);
      font-family: 'Work Sans', sans-serif;
      font-size: 16px;
      line-height: 136%;
      padding-right: 35px;
    }

  }

  .image {
    display: flex;
    justify-content: flex-end;

    img {
      width: 100%;
      object-fit: cover;
      object-position: 100%;
      border-radius: 20px;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;
    .image {
      img {
        padding-top: 50px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 50px 5%;
    .image {
      img {
        width: 100%;
        margin-top: 30px;
      }
    }
    .text {
      width: 100%;

      p {
        color: var(--primary);
        font-family: 'Work Sans', sans-serif;
        font-size: 13px;
        line-height: 116%;
        padding: 0;
      }
    }
  }
}