@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;0,900;1,700&display=swap');

.App {
  font-family: 'Work Sans', sans-serif;
}

.ant-modal-content {
  border-radius: 15px !important;

  .ant-modal-header {
    color: white !important;
    text-decoration-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    //background-color: var(--primary);
  }
}
