.first_section {
  background: linear-gradient(rgba(0, 0, 0, 0.13), rgba(0, 0, 0, 0.16)), url("../../../../public/My+.png");
  //background: url("../../../../public/My+.png");
  background-size: cover;
  background-position: 0;
  height: 100vh;
  padding: 0 20%;
  position: relative;

  .main_section {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .title {
      font-family: 'Work Sans', sans-serif;
      font-weight: bold;
      font-size: 52px;
      line-height: 112%;
      letter-spacing: 0.005em;
      color: #FFFFFF;
      margin: 0;
    }

    .subtitle {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 128%;
      letter-spacing: 0.005em;
      color: #FFFFFF;
      margin: 30px 0;
    }

    .detail {
      background-color: var(--secondary);
      border: none;
      width: 180px;
      height: 40px;
      border-radius: 48px;
      color: white;
      box-shadow: none;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }

    //button
    .button {
      display: inline-flex;
      height: 40px;
      width: 150px;
      border-radius: 45px;
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: .8em;
      letter-spacing: 1.5px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background-color: var(--secondary);
    }

    a {
      color: white;
      text-decoration: none;
      letter-spacing: 1px;
    }

    #button-7 {
      position: relative;
      overflow: hidden;
      cursor: pointer;
    }

    #button-7 a {
      position: relative;
      left: 0;
      transition: all .35s ease-Out;
    }

    #dub-arrow {
      width: 100%;
      height: 100%;
      background: white;
      left: -200px;
      position: absolute;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .20s ease-Out;
      bottom: 0;
    }

    #button-7 img {
      width: 20px;
      height: auto;
    }

    #button-7:hover #dub-arrow {
      left: 0;
    }

    #button-7:hover a {
      left: 150px;
    }

    @media screen and (min-width: 1000px) {
      h1 {
        font-size: 2.2em;
      }
      #container {
        width: 50%;
      }
    }
    .content {
      height: 60px;
      overflow:hidden;

      font-family: 'Lato', sans-serif;
      font-size: 35px;
      line-height: 40px;
      color: #ecf0f1;

      &__container {
        font-weight: 600;
        overflow: hidden;
        height: 40px;
        &:after, &:before {
          position: absolute;
          top: 0;

          color: #16a085;
          font-size: 42px;
          line-height: 40px;

          -webkit-animation-name: opacity;
          -webkit-animation-duration: 2s;
          -webkit-animation-iteration-count: infinite;
          animation-name: opacity;
          animation-duration: 2s;
          animation-iteration-count: infinite;
        }

        &__text {
          display: inline;
          float: left;
          margin: 0;
          background: linear-gradient(to top, var(--secondary) 50%, transparent 50%);
        }

        &__list {
          margin-top: 0;
          padding: 0;
          padding-left: 210px;
          //text-align: left;
          list-style: none;

          -webkit-animation-name: change;
          -webkit-animation-duration: 15s;
          -webkit-animation-iteration-count: infinite;
          animation-name: change;
          animation-duration: 15s;
          animation-iteration-count: infinite;

          &__item {
            line-height:40px;
            margin:0;
          }
        }
      }
    }
    @keyframes opacity {
      0%, 100% {opacity:0;}
      50% {opacity:1;}
    }

    @keyframes change {
      0%, 6.66%, 100% {transform:translate3d(0,0,0);}
      10%, 16.66% {transform:translate3d(0,-16.66%,0);}
      20%,26.66% {transform:translate3d(0,-33.32%,0);}
      30%,36.66% {transform:translate3d(0,-49.98%,0);}
      40%,46.66% {transform:translate3d(0,-66.64%,0);}
      50%,56.66% {transform:translate3d(0,-83.4%,0);}
      60%,66.66% {transform:translate3d(0,-66.64%,0);}
      70%,76.66% {transform:translate3d(0,-49.98%,0);}
      80%,86.66% {transform:translate3d(0,-33.32%,0);}
      90%,96.66% {transform:translate3d(0,-16.66%,0);}
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1200px){
  padding: 0 10%;
    background-position: 30%;
  }
  @media screen and (max-width: 767px) {
    padding: 0 5%;
    background-size: cover;
    background-position: 50%;


    .main_section {
      .title {
        font-size: 35px;
      }
      .content__container__text {
        display: none;
      }
      .content__container__list {
        padding: 0;
      }
    }

  }
}