.third_section {
  padding: 70px 20% 50px;
  background-image: url("../../../../public/bgi.jpg");
  background-size: cover;

  .card_cont {
    padding: 10px;

    .cardd {
      text-align: center;
      padding: 20px;
      height: 320px;
      align-items: center;
      border-radius: 10px;
      transition: .2s;

      .icon {
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
      }

      p {
        color: white;
        font-size: 15px;
        line-height: 20px;
        text-align: left;
      }

      .title {
        font-size: 18px;
      }

      .more_button {
        padding: 0 35px;
        border-radius: 10px;
        color: var(--secondary);
        border: 1px solid var(--secondary);

        &:hover {
          background-color: var(--secondary);
          color: white;
        }
      }

      .short_border {
        float: left;
        margin-top: 15px;
        margin-left: -39px;
        width: 2px;
        height: 50px;
        background-color: #C4C4C4;
      }

      &:hover {
        background-color: var(--primary);
      }
    }
  }

  //button
  .button {
    display: inline-flex;
    height: 40px;
    width: 150px;
    border: 2px solid white;
    //margin: 20px 20px 20px 20px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: .8em;
    letter-spacing: 1.5px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  a {
    color: white;
    text-decoration: none;
    letter-spacing: 1px;
  }

  #button-7 {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  #button-7 a {
    position: relative;
    left: 0;
    transition: all .35s ease-Out;
  }

  #dub-arrow {
    width: 100%;
    height: 100%;
    background: white;
    left: -200px;
    position: absolute;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .20s ease-Out;
    bottom: 0;
  }

  #button-7 img {
    width: 20px;
    height: auto;
  }

  #button-7:hover #dub-arrow {
    left: 0;
  }

  #button-7:hover a {
    left: 150px;
  }

  @media screen and (min-width: 1000px) {
    h1 {
      font-size: 2.2em;
    }
    #container {
      width: 50%;
    }
  }

  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;

  }

  @media screen and (max-width: 767px) {
    padding: 50px 5%;

  }

}