.sixth_section {
  padding: 80px 20%;

  .swiper_cont {
    font-family: 'Work Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary);

    .name {
      color: var(--secondary);
      font-size: 17px;
      line-height: 25px;

    }

    .opinion {
      text-align: justify;
    }

    p, img {
      margin: 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    img {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      margin-bottom: 10px;
      object-fit: cover;
    }

  }

  // Swiper-Slider

  .swiper-slide {
    text-align: center;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;

  }
  @media screen and (max-width: 767px) {
    padding: 50px 5%;
  }

}