.ant-back-top-icon {
  background-color: var(--secondary);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
//scroll-behavior: unset !important;
}

.upIcon {
  background-color: var(--secondary);
  color: white;
  padding: 15px 12px;
  border-radius: 35%;
  font-size: x-large;
}
