.fifth_section {
  padding: 100px 20%;

  .cards {
    .card_cont {
      padding: 0 10px;

      .cardd {
        height: 250px;
        padding: 0 20px 30px;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 1px 8px 11px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        transition: .3s;
        &:hover {
          background-color: #f3f3f3;
        }

        .icon_cont {
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            height: 50px;
            color: var(--secondary);
          }
        }

        .text {
          p {
            color: var(--primary);
            font-family: 'Work Sans', sans-serif;
            font-size: 15px;
            line-height: 18px;
          }

          .title {
            height: 40%;
          }

          .subtitle {
            height: 60%;
          }
        }
      }

    }
  }
  //MEDIA
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 60px 10%;

  }
  @media screen and (max-width: 767px) {
    padding: 50px 5%;
    .cardd {
      margin-bottom: 30px;
    }
  }
}