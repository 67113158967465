.first_about_section {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../../public/aboutUsWallpaper.jpg');
  background-size: cover;
  background-position: -10%;
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    font-weight: bold;
  }

  @media screen and (max-width: 767px) {
    background-position: 45%;

  }
}