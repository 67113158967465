.section_title {
  .title {
    color: var(--primary);
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
  }
  .line {
    border-bottom:3px solid var(--secondary);
    height: 100%;
    width: 55px;
  }
  @media screen and (max-width: 767px) {
    .title {
      font-size: 25px;
    }
  }
}